<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.modelo_relatorio.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.modelo_relatorio.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Relatorio', 'Inserir')"
      :sem-filtro="!buscarPermissao('Relatorio', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Relatorio', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :mostrar-acoes="true"
      :por-pagina="tabela.porPagina"
      :quantidade-itens="tabela.quantidadeItens"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
    >
      <!-- <template v-slot:item.data-table-select="{ item }">
        <div>
          <v-simple-checkbox
            v-model="item.isSelected"
            :disabled="!podeSelecionar(item.item)"
            @input="selecionarTabela(item.item, item.isSelected)"
          />
        </div>
      </template> -->
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              v-if="buscarPermissao('Relatorio', 'Editar')"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item @click="gerarRelatorio(slotProps.data)">
              {{ slotProps.data.apiEndpoint == 'Sql' ? 'Gerar' : 'Testar' }} relatório
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
    <filtro-relatorio ref="filtroRelatorio" />
    <filtro-relatorio-sql ref="filtroRelatorioSql" />
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import RelatorioService from '@common/services/cadastros/RelatorioService';
import Filtro from './components/filtro.vue';
import FiltroRelatorio from './components/FiltroRelatorio';
import FiltroRelatorioSql from './components/FiltroRelatorioSql.vue';
import helpers from '@common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
    FiltroRelatorio,
    FiltroRelatorioSql,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.modelo_relatorio.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'titulo',
            text: this.$t('modulos.modelo_relatorio.tabela.titulo'),
            sortable: true,
          },
          {
            value: 'tipoRelatorio',
            text: this.$t('modulos.modelo_relatorio.tabela.sistema'),
            sortable: true,
            formatter: (v) =>
              helpers.TipoRelatorioEnum?.find((el) => el?.value == v)?.text ??
              v,
          },
          {
            value: 'relatorioCategoria.nome',
            text: this.$t('modulos.modelo_relatorio.tabela.categoria'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Relatorio', 'Listar');
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.modelo_relatorio.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      RelatorioService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    validarExclusao() {
      return this.tabela.selecionados.some((item) =>
        ['Sistema', 'CertificadoPadarao'].includes(item.tipoRelatorio)
      );
    },
    excluirRegistros: function () {
      if (this.validarExclusao())
        return this.toastAlerta(
          this.$t(`modulos.modelo_relatorio.exclusao_validacao_tipo`)
        );

      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        this.tabela.selecionados.forEach((item) => {
          RelatorioService.excluir(item.id)
            .then(() => {
              this.listarRegistros();
              this.toastSucesso(
                this.$t(`modulos.modelo_relatorio.exclusao_sucesso`)
              );
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
              this.tabela.selecionados = [];
            });
        });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'modelo-relatorio-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'modelo-relatorio-editar',
        params: { id: item.id },
      });
    },
    podeSelecionar: function (item) {
      return !item.sistema;
    },
    selecionarTabela: function (item, selecionado) {
      if (!selecionado) {
        this.tabela.selecionados.splice(
          this.tabela.selecionados.indexOf(
            this.tabela.selecionados.find((el) => el.id == item.id)
          ),
          1
        );
      } else {
        this.tabela.selecionados.push(item);
      }
    },
    gerarRelatorio: function (item) {
      this.$refs.filtroRelatorio.abrirModal(item.id);
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
