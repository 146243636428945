<template>
  <div>
    <modal-padrao
      v-if="modalAberto"
      ref="modal"
      titulo="Gerar Relatório"
      :max-width="maxWidth"
      :icone-ok="false"
      :mostra-botao-salvar="mostrarBotaoSalvar"
      @ok="gerarRelatorio"
      @modal-fechado="destruirModal"
    >
      <div ref="parametros">
        <div
          v-for="parametro in endpointsParametros"
          :key="parametro.id"
          class="row"
        >
          <input-text
            v-model="buscarParametro(parametro).valor"
            class="col-12"
            :label="parametro.nome"
            :obrigatorio="parametro.obrigatorio"
          />
        </div>
      </div>
      <gerador-relatorio ref="gerador" />
    </modal-padrao>
  </div>
</template>
<script>
import { RelatorioService } from '@common/services';
import { GeradorRelatorio } from '@components/misc';
export default {
  components: {
    GeradorRelatorio,
  },
  data: function () {
    return {
      relatorioId: null,
      form: [],
      endpointsParametros: [],
      maxWidth: '500px',
      modalAberto: false,
      mostrarBotaoSalvar: true,
    };
  },
  methods: {
    abrirModal: async function (id) {
      this.mostrarBotaoSalvar = true;
      this.form = [];
      this.modalAberto = true;
      this.maxWidth = '500px';
      await this.$nextTick();
      this.relatorioId = id;
      this.buscar();
      this.$refs.modal.abrirModal();
    },
    destruirModal: async function(){
      await this.$nextTick();
      this.modalAberto = false;
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RelatorioService.buscar(this.relatorioId)
        .then((res) => {
          this.endpointsParametros = res.data.endpoint.endpointsParametros;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.modelo_relatorio.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          if(this.endpointsParametros.length == 0)
            this.gerarRelatorio();
        });
    },
    buscarParametro: function (parametro) {
      let pForm = this.form.find((el) => el.simbolo == parametro.simbolo);
      if (!pForm) {
        this.form.push({
          simbolo: parametro.simbolo,
          valor: '',
        });
        return this.buscarParametro(parametro);
      }

      return pForm;
    },
    gerarRelatorio: function () {
      this.mostrarBotaoSalvar = false;
      this.$refs.gerador.inicializarVisualizador(this.relatorioId, this.form);
      this.$refs.parametros.style.display = 'none';
      this.maxWidth = '90%';
    },
  },
};
</script>
