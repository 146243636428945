<template>
  <div class="row">
    <input-text
      v-model="filtro.codigo"
      class="col-12 col-md-6"
      :label="$t('modulos.modelo_relatorio.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.titulo"
      class="col-12 col-md-6"
      :label="$t('modulos.modelo_relatorio.filtro.titulo')"
      em-filtro
    />
    <input-select
      v-model="filtro.categoria"
      class="col-12"
      :options="opcoes.categoria"
      :label="$t('modulos.modelo_relatorio.filtro.categoria')"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import { DropdownModel } from '@common/models/geral/DropdownModel';
import CategoriaRelatorioService from '@common/services/cadastros/CategoriaRelatorioService';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        categoria: [],
      },
    };
  },
  mounted() {
    this.buscarCategoria();
  },
  methods: {
    buscarCategoria: function () {
      CategoriaRelatorioService.listar().then((res) => {
        this.opcoes.categoria = new DropdownModel(res.data.items);
      });
    },
  },
};
</script>
